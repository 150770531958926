























































import { Component, Vue } from 'vue-property-decorator';
import { readBairros } from '@/store/bairro/getters';
import { dispatchDeleteBairro, dispatchGetBairros } from '@/store/bairro/actions';
import { IBairro } from '@/interfaces/bairro';
import ModalConfirmacao from '@/components/ModalConfirmacao.vue';
import ModalDetalhesBairro from '@/components/ModalDetalhesBairro.vue';

@Component({
  components: {
    ModalConfirmacao,
    ModalDetalhesBairro,
  },
})
export default class ListarBairros extends Vue {
  public modalExclusaoAtivo: boolean = false;
  public modalDetalhesBairroAtivo: boolean = false;
  public bairroExclusao;
  public bairroDetalhes;

  public headers = [
    {
      text: 'ID',
      sortable: true,
      value: 'id',
      align: 'center',
    },
    {
      text: 'Nome',
      sortable: true,
      value: 'nome',
      align: 'center',
    },
    {
      text: 'Ações',
      align: 'center',
    },
  ];

  public options = {
    rowsPerPage: 10,
  };

  get bairros() {
    return readBairros(this.$store);
  }

  public abrirModalExclusao(bairro: IBairro) {
    this.bairroExclusao = bairro;
    this.modalExclusaoAtivo = true;
  }

  public async confirmarExclusao() {
    const excluiu = await dispatchDeleteBairro(this.$store, this.bairroExclusao);
    this.modalExclusaoAtivo = false;
  }

  public abrirModalDetalhesBairro(bairro: IBairro) {
    this.bairroDetalhes = bairro;
    this.modalDetalhesBairroAtivo = true;
  }

  public async mounted() {
    await dispatchGetBairros(this.$store);
  }
}
